import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "/",
      component: () => import('./views/Landing')
    },
    {
      path: '/business',
      name: 'business',
      component: () => import("./layouts/PlainView.vue"),
      children: [
        {
          path: '/business',
          name: '',
          component: () => import('./views/main/Business')
        },
        {
          path: '/runners',
          name: '',
          component: () => import('./views/main/Runners')
        },
      ]
    },
    {
      path: "/coming-soon",
      name: "coming-soon",
      component: () => import("./views/ComingSoon"),
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () => import("./views/PrivacyPolicy"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Contact"),
    },
    {
      path: "*",
      redirect: "/home",
    }
  ]
})

export default router;