import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue } from "bootstrap-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import router from './router'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'animate.css';
import "./assets/scss/main.scss";
import "./main.css"
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  bodyClassName: "custom-toast",
};

Vue.use(BootstrapVue);
Vue.use(FontAwesomeIcon);
Vue.use(Toast, options);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
